import * as yup from "yup";

export const contactSchema = yup.object().shape({
  // OptionOne
  name: yup
    .string()
    .min(3, "Mínimo 3 caracteres")
    .max(50, "Máximo 50 caracteres"),
  lastName: yup
    .string()
    .min(3, "Mínimo 3 caracteres")
    .max(512, "Máximo 50 caracteres"),
  document_number: yup
    .string()
    .min(3, "Mínimo 3 caracteres")
    .max(24, "Máximo 24 caracteres"),

  email: yup
    .string()
    .email("Formato incorrecto")
    .max(320, "Máximo 320 caracteres"),
  message: yup
    .string()
    .min(3, "Mínimo 3 caracteres")
    .max(700, "Máximo 700 caracteres"),
});
