import React, { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

const NavBar = () => {
  const navigate = useNavigate();

  const [viewMenu, setViewMenu] = useState(false);
  const [subMenuView, setSubMenuView] = useState(false);

  return (
    <Nav>
      <Logo
        onClick={() => {
          navigate("./");
        }}
      >
        <img src="../assets/logo-green-nuevo.png" />
      </Logo>
      <WrapperBurger>
        <div onClick={() => setViewMenu(!viewMenu)}>
          {!viewMenu ? (
            <img src="../assets/burgen-menu.png" width="30px" />
          ) : (
            <img src="../assets/cross-icon.png" width="30px" />
          )}
        </div>
      </WrapperBurger>
      {/* <img src="../assets/cross-icon.png" width="30px" /> */}

      <ResponsiveLinks viewMenu={viewMenu}>
        <ButtonResponsive
          onClick={() => {
            navigate("./");
            setViewMenu(!viewMenu);
          }}
        >
          Inicio
        </ButtonResponsive>
        <ButtonResponsive
          onClick={() => {
            navigate("./obras");
            setViewMenu(!viewMenu);
          }}
        >
          Obras
        </ButtonResponsive>
        <SpecialButtonResponsive subMenuView={subMenuView}>
          <div>
            <ButtonResponsive onClick={() => setSubMenuView(!subMenuView)}>
              Empresa
            </ButtonResponsive>{" "}
            <img
              onClick={() => setSubMenuView(!subMenuView)}
              src="../assets/arrow-icon.png"
              width="10px"
            />
          </div>
          <SubButtonResponsive subMenuView={subMenuView}>
            <ButtonResponsive
              onClick={() => {
                navigate("./empresa");
                setViewMenu(!viewMenu);
              }}
            >
              Sobre Green
            </ButtonResponsive>
            <ButtonResponsive
              onClick={() => {
                navigate("./canal-transparente");
                setViewMenu(!viewMenu);
              }}
            >
              Canal Transparente
            </ButtonResponsive>
            <ButtonResponsive
              onClick={() => {
                navigate("./equipamiento");
                setViewMenu(!viewMenu);
              }}
            >
              Equipamiento
            </ButtonResponsive>
          </SubButtonResponsive>
        </SpecialButtonResponsive>
        <ButtonResponsive
          onClick={() => {
            navigate("./contacto");
            setViewMenu(!viewMenu);
          }}
        >
          Contacto
        </ButtonResponsive>
      </ResponsiveLinks>

      <Links>
        <Button
          onClick={() => {
            navigate("./");
          }}
        >
          Inicio
        </Button>
        <Button
          onClick={() => {
            navigate("./obras");
          }}
        >
          Obras
        </Button>
        <SpecialButton>
          <div>Empresa</div>

          <SubButtons>
            <Button
              onClick={() => {
                navigate("./empresa");
              }}
            >
              Sobre Green
            </Button>
            <Button
              onClick={() => {
                navigate("./canal-transparente");
              }}
            >
              Canal Transparente
            </Button>
            <Button
              onClick={() => {
                navigate("./equipamiento");
              }}
            >
              Equipamiento
            </Button>
          </SubButtons>
        </SpecialButton>
        <Button
          onClick={() => {
            navigate("./contacto");
          }}
        >
          Contacto
        </Button>
      </Links>
    </Nav>
  );
};

export default NavBar;

const Nav = styled.div`
  /* font-family: "Josefin Sans", sans-serif; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: calc(100% - 30rem);
  padding: 1rem 15rem;
  background-color: #016734;

  position: relative;

  @media (max-width: 900px) {
    width: calc(100% - 14rem);
    padding: 1rem 7rem;
  }

  @media (max-width: 768px) {
    width: calc(100% - 6rem);
    padding: 1rem 3rem;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
`;

const WrapperBurger = styled.div`
  display: none;

  div {
    cursor: pointer;
  }

  @media (max-width: 900px) {
    display: block;
  }
`;

const Links = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  @media (max-width: 900px) {
    display: none;
  }
`;

const Button = styled.button`
  font-size: 1.3rem;
  border: none;
  background-color: transparent;
  border-radius: none;
  padding: 0;
  color: #fff;
  cursor: pointer;

  :hover {
    color: #e1e1e1;
  }
`;

const SpecialButton = styled.button`
  font-size: 1.3rem;
  border: none;
  background-color: transparent;
  border-radius: none;
  color: #fff;
  position: relative;
  cursor: pointer;

  :hover {
    color: #e1e1e1;

    div:last-child {
      position: absolute;
      width: max-content;
      z-index: 99;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
`;

const SubButtons = styled.div`
  display: none;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: #016734;
  button {
    padding: 0.5rem;
    :hover {
      background-color: #017839;
    }
  }
`;

const ResponsiveLinks = styled.div`
  position: absolute;
  top: 100%;
  /* overflow: visible; */
  z-index: 999;
  left: ${(p) => (!p.viewMenu ? "-1000px" : "0px")};
  width: 50vw;
  height: auto;
  display: flex;
  flex-direction: column;

  transition: all 0.5s ease-in-out;
`;

const ButtonResponsive = styled.div`
  background-color: #016734;
  color: #fff;
  padding: 0.5rem;
  width: calc(100% - 1rem);
  font-size: 1.2rem;
  cursor: pointer;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  :hover {
    background-color: #017839;
  }
`;

const SpecialButtonResponsive = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #016734;
  width: calc(100% - 2rem);
  padding: 0 1rem;
  :hover {
    background-color: #017839;
  }

  div {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    div {
      background-color: transparent;
    }
    img {
      transition: all 0.3s ease-in-out;
      transform: ${(p) =>
        !p.subMenuView ? "rotateX(0deg)" : "rotateX(180deg)"};
    }
  }
`;

const SubButtonResponsive = styled.div`
  display: ${(p) => (p.subMenuView ? "flex" : "none")};
  flex-direction: column;

  div {
    display: ${(p) => (p.subMenuView ? "flex" : "none")};

    :hover {
      background-color: #445859;
    }
  }
`;
