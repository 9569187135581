import React from "react";
import styled from "styled-components";
import BodyEmpresa from "../components/empresa/BodyEmpresa";
import HeaderEmpresa from "../components/empresa/HeaderEmpresa";
import ValuesEmpresa from "../components/empresa/ValuesEmpresa";
import Footer from "../components/layout/Footer";

const Empresa = () => {
  return (
    <Wrapper>
      <HeaderEmpresa />
      <BodyEmpresa />
      <ValuesEmpresa />
      <Footer />
    </Wrapper>
  );
};

export default Empresa;

const Wrapper = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
