import React from "react";
import styled from "styled-components";

const Footer = () => {
  return (
    <Wrapper>
      <p>
        Todos los derechos reservados / Green S.A. / Copyrights 2022 All rights
        reserved.
      </p>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  height: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  background-color: #a6958b;
  p {
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2rem;
    text-align: center;
  }

  @media (max-width: 900px) {
    p {
      font-size: .9rem;
    }
  }
`;
