import React from "react";
import styled from "styled-components";

const Services = () => {
  const array = [
    {
      img: "./assets/img-obras-publicas.gif",
      title: "OBRAS PÚBLICAS",
      color: "#49b7a7",
      description:
        "Nuestra trayectoria y antecedentes en obras publicas han consolidado un fuerte posicionamiento, posibilitándonos llevar nuestra misión a cada rincón del país.",
    },
    {
      img: "./assets/img-obras-privadas.gif",
      title: "OBRAS PRIVADAS",
      color: "#48a0b5",
      description:
        "En el año 1999, el grupo decidió diversificar sus inversiones proyectando y desarrollando obras privadas, tales como barrios privados y hoteles ubicados en San Luis y Mendoza.",
    },
    {
      img: "./assets/img-arquitectura.gif",
      title: "ARQUITECTURA",
      color: "#b25680",
      description:
        "El diseño y proyección de nuestras obras arquitectónicas representa otra ventaja competitiva que garantiza la calidad de nuestros trabajos en construcciones de todo tipo.",
    },
  ];

  return (
    <Wrapper className="container">
      {array.map((e, index) => (
        <Card key={index}>
          <Image>
            <img src={e.img} />
          </Image>
          <Title color={e.color}>{e.title}</Title>
          <Description>{e.description}</Description>
        </Card>
      ))}
    </Wrapper>
  );
};

export default Services;

const Wrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

const Image = styled.div`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }

  @media (max-width: 900px) {
    width: 140px;
  }
`;

const Title = styled.h3`
  color: ${(p) => p.color};
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 2rem;

  @media (max-width: 900px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.p`
  font-family: "Open Sans", sans-serif;
  color: #696969;
  font-size: 1.2rem;
  text-align: center;

  @media (max-width: 900px) {
    font-size: .95rem;
  }
`;
