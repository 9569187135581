import React from "react";
import styled from "styled-components";
import { ButtonCm } from "../commons/StylesGlobal";
import Carousel from "react-elastic-carousel";
import { useNavigate } from "react-router";

const Header = () => {
  const navigate = useNavigate();

  const array = [
    {
      title: "DISEÑAMOS, PROYECTAMOS Y",
      subTitle: "CONSTRUIMOS",
      image: "../assets/slider-image/bannerHeader.png",
      description: [
        {
          text: "GREEN S.A. se especializa en todo tipo de obras de infraestructura; viales",
        },
        {
          text: "hidráulicas; también de arquitectura. Se construyen y licitan importantes",
        },
        {
          text: "obras en Argentina y el exterior.",
        },
      ],
      goTo: "./empresa",
      textButton: "Conozcanos",
    },
    {
      title: "Autopista de las",
      subTitle: "serranías puntanas",
      image: "../assets/slider-image/background-obra-01.jpg",
      description: [
        {
          text: "Esta obra sobre la Ruta Nacional 7 tiene una extensión de",
        },
        {
          text: "212 kilómetros de calzada en hormigón, realizada con maquinaria de alta tecnología, única en",
        },
        {
          text: "América del Sur.",
        },
      ],
      goTo: "./obras/ruta-7-san-luis",
      textButton: "Ver Proyecto",
    },
    {
      title: "DIQUE",
      subTitle: "LAS PALMERAS",
      image: "../assets/slider-image/background-dique.jpg",
      description: [
        {
          text: "Este dique contiene un espejo de agua de 186 hectáreas y embalsa un volumen de 24 hm3, por",
        },
        {
          text: "lo que es uno de los más grandes de la provincia de San Luis.",
        },
      ],
      goTo: "./obras/dique-las-palmeras",
      textButton: "Ver Proyecto",
    },
    {
      title: "TERMINAL",
      subTitle: "SAN LUIS",
      image: "../assets/slider-image/background-terminal.jpg",
      description: [
        {
          text: "El moderno espacio, contempla un techo inspirado en las líneas ondulantes",
        },
        {
          text: "de las sierras de San Luis, de manera que se integra visualmente al paisaje",
        },
        {
          text: "circundante.",
        },
      ],
      goTo: "./obras/terminal-san-luis",
      textButton: "Ver Proyecto",
    },
  ];

  return (
    <CarouselFake
      transitionMs={500}
      showArrows={false}
      autoPlaySpeed={5000}
      itemsToShow={1}
      enableAutoPlay
    >
      {array.map((arr) => (
        <Wrapper image={arr.image}>
          <Container>
            <Title>
              <h2>{arr.title}</h2>
              <p>{arr.subTitle}</p>
            </Title>
            <Description>
              {arr.description.map((text) => (
                <p>{text.text}</p>
              ))}
            </Description>
            <Button
              onClick={() => {
                navigate(arr.goTo);
              }}
            >
              {arr.textButton}
            </Button>
          </Container>
        </Wrapper>
      ))}
    </CarouselFake>
  );
};

export default Header;

const CarouselFake = styled(Carousel)`
  height: fit-content;
  position: relative;
  border-radius: 20px;
  width: 100%;
  padding: 0;
  .rec-slider-container {
    margin: 0;
  }
  /* .rec.rec-arrow {
        display: none;
        visibility: hidden;
    } */
  .rec.rec-pagination {
    position: absolute;
    bottom: 1rem;
    left: 1.5rem;
    button {
      background: #b5b6b6;
      box-shadow: none;
    }
    .rec-dot_active {
      border: none;
      background-color: #fff;
    }
  }
`;

const Wrapper = styled.div`
  height: 550px;
  overflow: hidden;
  width: 100%;
  ${(p) => `
    background-image: url("${p.image}");
  `};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    height: 650px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;

  @media (max-width: 900px) {
    align-items: center;
    width: calc(100% - 2rem);
    padding: 1rem;
  }
`;

const Title = styled.div`
  margin: 0;
  padding: 0;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 300;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-transform: uppercase;
  h2 {
    color: #fff;
    font-size: 5.5rem;
    font-weight: 300;
  }

  p {
    font-size: 5.5rem;
    color: #ddd485;
  }

  @media (max-width: 900px) {
    align-items: center;

    h2 {
      font-size: 4rem;
      text-align: center;
    }

    p {
      font-size: 4rem;
      text-align: center;
    }
  }
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  color: #fff;

  @media (max-width: 900px) {
    font-size: 1rem;
    text-align: center;
  }
`;

const Button = styled.button`
  background-color: #12a451;
  font-size: 1rem;
  color: #fff;
  border: none;
  padding: 15px 32px;
  text-align: center;
  width: fit-content;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  :hover {
    background-color: #1bbd61;
  }
`;
