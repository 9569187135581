import NavBar from "./components/layout/NavBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import Empresa from "./pages/Empresa";
import CanalTransparente from "./pages/CanalTransparente";
import Equipamiento from "./pages/Equipamiento";
import Obras from "./pages/Obras";
import Contacto from "./pages/Contacto";

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route index path="/" element={<Main />}></Route>
        <Route index path="/empresa" element={<Empresa />}></Route>
        <Route
          index
          path="/canal-transparente"
          element={<CanalTransparente />}
        ></Route>
        <Route index path="/equipamiento" element={<Equipamiento />}></Route>
        <Route path="/obras/:id" element={<Obras />}></Route>
        <Route path="/obras" element={<Obras />}></Route>
        <Route path="/contacto" element={<Contacto />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
