import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import Footer from "../components/layout/Footer";
import ObrasSliderBalls from "../components/obras/ObrasSliderBalls";
import ParticularObra from "../components/obras/ParticularObra";

const Obras = () => {
  const { id } = useParams();

  //STATES
  const [oneObra, setOneObra] = useState(null);

  useEffect(() => {
    if (!!id) {
      setOneObra(null);
      watcherId();
    }
  }, [id]);

  const obrasArray = [
    {
      title: "OBRAS VIALES",
      component: [
        {
          path: "paseo-del-bajo",
          alt: "Paseo del bajo",
          img: "../assets/obras/paseodelbajo-ball.png",
        },
        {
          path: "autopista-villa-mercedes",
          alt: "Auto pista villa mercedes, San Luis, Argentina",
          img: "../assets/obras/a-villa-mercedes.png",
        },
        {
          path: "ruta-nacional-81",
          alt: "Ruta nacional 81, Formosa, Argentina",
          img: "../assets/obras/ruta-nac-81.png",
        },
        {
          path: "malla-330",
          alt: "Malla 330, Ruta 7, Mendoza, Argentina",
          img: "../assets/obras/malla-330.png",
        },
        {
          path: "autopista-25-de-mayo",
          alt: "Autopista 25 de Mayo, San Luis, Argentina",
          img: "../assets/obras/a-25-mayo.png",
        },
        {
          path: "el-sosneado",
          alt: "Ruta 40 Sosneado, Mendoza, Argentina",
          img: "../assets/obras/ruta-sosneado.png",
        },
        {
          path: "ruta-14",
          alt: "Autovia Ruta Nacional 14, Entre Rios, Argentina",
          img: "../assets/obras/a-ruta-nac-14.png",
        },
        {
          path: "corredor-vial",
          alt: "Corredor Vial, San Luis, Argentina",
          img: "../assets/obras/corredor-vial.png",
        },
        {
          path: "ruta-la-quebrada",
          alt: "Ruta la quebrada nogoli, San Luis, Argentina",
          img: "../assets/obras/ruta-laquebrada.png",
        },
        {
          path: "nudo-paso",
          alt: "Nudo vial Acceso Sur, Mendoza, Argentina",
          img: "../assets/obras/nudo-vial-acc-sur.png",
        },
        {
          path: "acceso-este",
          alt: "Obra Acceso Este Mendoza, Argentina",
          img: "../assets/obras/obra-acc-este.png",
        },
        {
          path: "ruta-7-san-luis",
          alt: "Corredor bioceanico San Luis, Argentina",
          img: "../assets/obras/a-corredor-serranias.png",
        },
        {
          path: "nudo-costanera",
          alt: "Nudo costanera Mendoza, Argentina",
          img: "../assets/obras/nudo-costanera.png",
        },
      ],
    },
    {
      title: "OBRAS FERROVIARIAS",
      component: [
        {
          path: "viaducto-san-martin",
          alt: "Viaducto San Martin",
          img: "../assets/obras/viaducto-sm.png",
        },
      ],
    },
    {
      title: "OBRAS HIDRÁULICAS",
      component: [
        {
          path: "canal-intervalles",
          alt: "Canal Intervalle, Jujuy, Argentina",
          img: "../assets/obras/canal-intervalles.png",
        },
        {
          path: "acueducto",
          alt: "Acueducto Nogoli, San Luis, Argentina",
          img: "../assets/obras/acueducto-nogoli.png",
        },
        {
          path: "dique-las-palmeras",
          alt: "Dique Las Palmeras, San Luis, Argentina",
          img: "../assets/obras/dique-las-palmeras.png",
        },
      ],
    },
    {
      title: "OBRAS DE ARQUITECTURA",
      component: [
        {
          path: "barrio-papa-francisco",
          alt: "Barrio Papa Francisco",
          img: "../assets/obras/papa-francisco.png",
        },
        {
          path: "procrear-estacion-buenos-aires",
          alt: "Procrear Estación Buenos Aires",
          img: "../assets/obras/procrear-estacion.png",
        },
        {
          path: "torres-parque-avellaneda",
          alt: "Torres Parque Avellaneda, Buenos Aires, Argentina",
          img: "../assets/obras/torres-parque.png",
        },
        {
          path: "viviendas-procrear",
          alt: "Barrio Pro.cre.ar Mendoza, Argentina",
          img: "../assets/obras/barrio-atsa.png",
        },
        {
          path: "hotel-vista-suites",
          alt: "Hotel vista suites, San Luis, Argentina",
          img: "../assets/obras/hotel-vista-suites.png",
        },
        {
          path: "terminal-san-luis",
          alt: "Terminal de ómnibus, San Luis, Argentina",
          img: "../assets/obras/terminal-omnibus.png",
        },
      ],
    },
  ];

  const SubObras = [
    {
      id: "paseo-del-bajo",
      title: "PASEO",
      subTitle: "DEL BAJO",
      imgHeader: "../assets/obras/header-paseodelbajo-ball.jpg",
      titleDescription: "",
      description: [
        {
          text: "El sector de traza que corresponde al contrato de GREEN S.A.-C.E.O.S.A. PBT UTE es el Tramo B, que se desarrolla a partir del inicio de las rampas de acceso a trincheras, entre las calles Humberto Primo y Carlos Calvo (en la progresiva 0+736,15 de la traza principal, donde concluye el Tramo A), hasta el inicio de la traza adyacente al RER, aproximadamente a la altura de la calle Bartolomé Mitre (en la progresiva 2+234,10, donde conecta con el Tramo C).",
        },
        {
          text: "En las obras a realizar en el Tramo B predominan fundamentalmente las estructuras tipo con Sección de Trinchera, en sectores alternados será abierto, y cubierto en los sectores correspondientes a puentes y cruces peatonales.",
        },
        {
          text: "La ejecución de la obra para los muros de contención en la trinchera se hará con la tecnología de Muro Colado.",
        },
        {
          text: "La transición desde el nivel de calzada actual, que corresponde al sur en el empalme con el Tramo A, hasta alcanzar la rasante con el gálibo de diseño en la trinchera se ejecutarán mediante rampas.",
        },
      ],
      imgDescription: "../assets/obras/description-paseodelbajo-ball.png",
      secondTitle: "VISTAS DE OBRA",
      secondImages: "../assets/obras/vistas-paseodelbajo-ball-1.jpg",
    },
    {
      id: "autopista-villa-mercedes",
      title: "AUTOPISTA",
      subTitle: "VILLA MERCEDES",
      imgHeader: "../assets/obras/header-a-villa-mercedes.jpg",
      titleDescription:
        "La Ruta Provincial 55 es una autopista pavimentada de 458 km que recorre la Provincia de San Luis, Argentina, de norte a sur en el extremo oriental, cerca del límite con las provincias de Córdoba y La Pampa.",
      description: [
        {
          text: "Originalmente este camino formaba parte de la Ruta Nacional 148. Mediante el Decreto Nacional 1595 del año 1979,1 el tramo al sur del empalme con la Ruta Nacional 188 en el pueblo de Nueva Galia, pasó a jurisdicción provincial. De esta manera se le cambió la denominación a Ruta Provincial 55.",
        },
        {
          text: "GREEN S.A.  construyó un tramo de 60km al sur de Villa Mercedes, desde Buena Esperanza hasta un poco más al sur de Nueva Galia.",
        },
        {
          text: "En diciembre de 2001 el gobierno de San Luis licitó la construcción de una autovía de 176 km entre Villa Mercedes y Merlo.4 Luego de 960 días de construcción, la obra fue inaugurada el 10 de agosto de 2007 con el nombre de Autopista de los Comechingones.",
        },
        {
          text: "El 11 de junio de 2009 comenzaron los trabajos para convertir en autovía el tramo de 256 km entre Villa Mercedes y Arizona, cerca del límite con la Provincia de La Pampa.",
        },
      ],
      imgDescription: "../assets/obras/Nueva-Galia-1.jpg",
      secondTitle: "PROCESO DE OBRA",
      secondImages: "../assets/obras/Vistas-Galia-1.jpg",
    },
    {
      id: "ruta-nacional-81",
      title: "RUTA 81",
      subTitle: "FORMOSA",
      imgHeader: "../assets/obras/Cabecera-r81-1.jpg",
      titleDescription:
        "Este camino forma parte del Eje del Capricornio, definido por IIRSA como uno de los tres ejes de integración en Argentina.",
      description: [
        {
          text: "La Ruta Nacional 81 es una carretera argentina, que atraviesa toda la Provincia de Formosa, y finaliza en el este de la Provincia de Salta, uniendo la Ruta Nacional 11 en las cercanías de la ciudad de Formosa con la Ruta Nacional 34 en el paraje El Cruce, entre las localidades de Embarcación y General Ballivián. Desde 2008 toda la ruta está pavimentada. Su extensión total es de 680 km.",
        },
        {
          text: "Este camino forma parte del Eje del Capricornio, definido por IIRSA como uno de los tres ejes de integración en Argentina.",
        },
        {
          text: "Antes de 1968 la ruta era de tierra, por lo que era imposible transitar por la misma durante las lluvias o después de las mismas. De esta manera la única conexión permanente entre las localidades a la vera de la ruta era el Ferrocarril General Belgrano.",
        },
        {
          text: "En el 2004 comenzó la construcción de un tramo en la provincia de Formosa, dividiéndose  éste en siete secciones para construirlas en paralelo. En 2007 sólo restaban 5,8 km para terminar la obra en la sección ubicada entre los km 1606 y 1612 entre Los Chiriguanos e Ingeniero Juárez.",
        },
      ],
      imgDescription: "",
      secondTitle: "VISTAS DE OBRA",
      secondImages: "",
    },
    {
      id: "malla-330",
      title: "MALLA",
      subTitle: "330",
      imgHeader: "../assets/obras/Cabecera-Malla-1.jpg",
      titleDescription:
        "El trabajo de la Malla 330 de reparación y mantenimiento se realiza en la Ruta Nacional Nº 7, en el tramo del empalme de la Ruta Nacional Nº 40 y el límite con Chile, provincia de Mendoza.",
      description: [
        {
          text: "La Ruta Nacional Nº 7 vincula a Argentina, Brasil, Uruguay y Paraguay con Chile, a través del Tunel Cristo Redentor, conectándolos, a su vez, con los países del oeste Asiático. Cabe señalar que la producción llega a través del Tunel Cristo Redentor a los puertos chilenos y desde allí es trasladada a las terminales del oeste Asiático.",
        },
        {
          text: "Por tal motivo, la mencionada ruta consiste en una vía de vital importancia para el intercambio comercial, motivo por el cual las interrupciones del tránsito como consecuencia de las nevadas en la alta montaña generan grandes inconvenientes en la economía. Ante esta situación, las obras a realizarse contribuirán a una circulación más segura y fluída.",
        },
        {
          text: "Asimismo, la Ruta Nacional Nº 7 consiste en un importante corredor turístico, con centros de atracción para visitantes nacionales y extranjeros. Dicha ruta permite llegar al Centro de Deportes Invernales Penitentes y al Parque Nacional Aconcagua, ambos visitados por turistas de todo el mundo",
        },
      ],
      imgDescription: "../assets/obras/Malla-330-1.jpg",
      secondTitle: "VISTAS DE OBRA",
      secondImages: "../assets/obras/Vistas_Malla-330-1.jpg",
    },
    {
      id: "autopista-25-de-mayo",
      title: "AUTOPISTA",
      subTitle: "25 DE MAYO",
      imgHeader: "../assets/obras/25-de-mayo-top-1.jpg",
      titleDescription:
        "En la provincia de San Luis se puede visitar la Ciudad de La Punta y la Villa de la Quebrada a través de una moderna autopista iluminada y proyectada por GREEN S.A.",
      description: [
        {
          text: "La obra, inaugurada en abril de 2013, consiste en una moderna autopista que conecta la Ciudad de San Luis con las localidades de La Punta, Villa de la Quebrada y Nogolí en la Provincia de San Luis.",
        },
        {
          text: "En una longitud de 34 kilómetros se construyeron dos calzadas de pavimento asfáltico de 6,70m de ancho, separadas por un cantero central de 1.50m de ancho. La obra se complementó con iluminación, una ciclovía de 2.00m de ancho, espacios verdes y rotondas en los accesos a las localidades.",
        },
      ],
      imgDescription: "../assets/obras/Autopista-25-de-mayo-1.jpg",
      secondTitle: "VISTAS DE OBRA",
      secondImages: "../assets/obras/vistas-25-de-mayo-1.jpg",
    },
    {
      id: "el-sosneado",
      title: "RUTA 40",
      subTitle: "EL SOSNEADO",
      imgHeader: "../assets/obras/Sosneado-header-1.jpg",
      titleDescription:
        "Este camino repavimentado es un eje fundamental y estratégico en la comunicación Norte-Sur de la provincia de Mendoza.",
      description: [
        {
          text: "Este nuevo tramo de ruta en construcción conformará un eje fundamental y estratégico en la comunicación Norte-Sur de la provincia de Mendoza. Está  comprendido entre las rutas nacionales N°144 en su paso por la localidad de El Sosneado, San Rafael, y la ruta nacional N°143 en su paso por la localidad de Pareditas, San Carlos, Provincia de Mendoza.",
        },
        {
          text: "El proyecto generará un beneficio directo en el tránsito local, nacional e internacional que se desplaza dentro del territorio mendocino, presentando un ahorro de más de 70 kilómetros a quienes deban viajar desde Mendoza hacia el departamento de Malargüe, con el correspondiente impacto en el desarrollo comercial, cultural, social y turístico de las áreas comprendidas. La obra potenciará también el acceso a unos de los centros turísticos emblemáticos de Mendoza, como es la Laguna del Diamante,  y el complejo invernal de Las Leñas.",
        },
      ],
      imgDescription: "../assets/obras/Sosneado-1.jpg",
      secondTitle: "VISTAS DE OBRA",
      secondImages: "../assets/obras/El-Sosneado-1.jpg",
    },
    {
      id: "ruta-14",
      title: "RUTA",
      subTitle: "NACIONAL 14",
      imgHeader: "../assets/obras/Cabecera-R14-1.jpg",
      titleDescription:
        "La Ruta Nacional 14 “José Gervasio Artigas” es una carretera de la República Argentina. Nace en la localidad de Ceibas, provincia de Entre Ríos, en confluencia con la Ruta Nacional 12.",
      description: [
        {
          text: "La Ruta Nacional 14 “José Gervasio Artigas” es una carretera de la República Argentina. Nace en la localidad de Ceibas, provincia de Entre Ríos, en confluencia con la Ruta Nacional 12, y en su camino bordea al río Uruguay culminando en la ciudad de Bernardo de Irigoyen, Misiones. Es una de las más transitadas del país, al ser el punto de entrada para el tráfico comercial desde el Brasil.",
        },
        {
          text: "La autovía tiene una extensión total de la de 507 kilómetros en las provincias de Entre Ríos y Corrientes.",
        },
        {
          text: "El tramo 3 sección 2 en la Provincia de Entre Ríos, fue realizado por Green y va desde localidad de Ubajay hasta la intersección con la Ruta Nacional Nº18, longitud del tramo 35 km.",
        },
      ],
      imgDescription: "../assets/obras/Ruta-14-1.jpg",
      secondTitle: "VISTAS DE OBRA",
      secondImages: "../assets/obras/Vistas_Ruta14-1.jpg",
    },
    {
      id: "corredor-vial",
      title: "CORREDOR",
      subTitle: "VIAL",
      imgHeader: "../assets/obras/Portada_Poder_judicial-1.jpg",
      titleDescription:
        "Este corredor vial central es una solución al tránsito y a la circulación por la cantidad de vehículos que transitan por este sector interno a la Ruta Nacional 7 en San Luis.",
      description: [
        {
          text: "Esta obra está compuesta por una avenida de circunvalación que atraviesa la ciudad de San Luis, en una extensión de 9 kilómetros iluminados con tecnología LED, la cual proporciona una mejor visibilidad y ahorro de energía. Además puentes y colectoras que unen las diferentes vías del corredor hacia el centro de la provincia.",
        },
        {
          text: "Además esta importante obra vial comprende rotondas y una extensión de 1.600 metros, doble mano. También cuenta con antenas de wi-fi para la cartelería inteligente que se operará desde una central. Los carteles se irán actualizando en forma permanente para que el automovilista tenga la mejor información sobre los lugares que recorre.",
        },
      ],
      imgDescription: "../assets/obras/Corredor_Vial-1.jpg",
      secondTitle: "VISTAS DE OBRA",
      secondImages: "../assets/obras/Progreso-Corredor-vial-1.jpg",
    },
    {
      id: "ruta-la-quebrada",
      title: "RUTA",
      subTitle: "LA QUEBRADA",
      imgHeader: "../assets/obras/Portada_Nogoli-1.jpg",
      titleDescription:
        "Este tramo de doce kilómetros (sobre un total de casi 50), es el tercero que se construye sobre la Ruta Provincial N° 3.",
      description: [
        {
          text: "La autopista realizada en San Luis , en este tramo, posee 12 kilómetros, es el tercero que construye GREEN S.A. sobre la importante Ruta Provincial 3. Los dos tramos anteriores fueron para unir San Luis con la Ciudad de La Punta, y esta última con Villa La Quebrada.",
        },
        {
          text: "La autovía tiene dos calzadas de 6,70 metros de ancho cada una, separadas por un cantero central, al igual que en los tramos anteriores. Además de la ruta tradicional la obra contempla luminarias en los retornos y rotandas.",
        },
      ],
      imgDescription: "../assets/obras/Ruta-la-quebrada-1.jpg",
      secondTitle: "VISTAS DE OBRA",
      secondImages: "../assets/obras/Vistas-Nogoli-1.jpg",
    },
    {
      id: "nudo-paso",
      title: "NUDO VIAL",
      subTitle: "ACC. SUR Y J.J.PASO",
      imgHeader: "../assets/obras/Portada_Nudo_paso-1.jpg",
      titleDescription:
        "Para el desarrollo de esta obra se trabajó con 23 vigas, de 20 toneladas cada una y de una longitud aproximada de 23 metros. Este nudo vial está construido con el objetivo de generar un intercambiador ubicado en la intersección de la Ruta Nacional 40 (Acceso Sur) y Ruta Provincial 10 (Juan José Paso), para resolver  la circulación del gran caudal vehicular.",
      description: [
        {
          text: "La obra consistió en la construcción de un intercambiador ubicado en la intersección de la Ruta Nacional N° 40 (Acceso Sur) y Ruta Provincial N° 10 (Juan José Paso), para resolver la circulación del gran caudal vehicular que satura y congestiona la rotonda de ingreso a los departamentos de Luján de Cuyo y Maipú.",
        },
        {
          text: "El proyecto contempló la ejecución de dos puentes. Uno, paralelo al actual sobre la RN. Nº 40 y el otro perpendicular a calle Paso, para permitir la continuidad de la calle Sáez al Oeste de la Ruta Nacional Nº 40, además la ejecución de las cuatro ramas del intercambiador (hojas de trébol) para todos los movimientos de la intersección.",
        },
        {
          text: "En esta importante la estructura de los puentes fue totalmente en hormigón armado in situ y con vigas y prelosas pretensadas. Para el montaje se utilizaron dos grúas de gran porte, que permitieron colocar en su lugar la pesada estructura, sin afectar el intenso tránsito que circulaba por la zona de obra.",
        },
        {
          text: "También se realizaron trabajos de ensanches del puente existente, ampliándose en 3,60 metros. Esto otorga mayor seguridad en las dos ramas de ingreso y egreso desde Maipú al Acceso Sur y viceversa.",
        },
        {
          text: "El paquete estructural para la calzada principal está integrado por una Sub Base de agregado pétreo de 20 cm. de espesor, una base de material pétreo y suelo de 20 cm. de espesor y una carpeta de mezcla bituminosa tipo concreto asfáltico de 10 cm. de espesor, con un ancho final de la calzada de 7.50 metros,  para cada sentido de circulación",
        },
      ],
      imgDescription: "../assets/obras/Nudo_Paso-1.jpg",
      secondTitle: "PROCESO DE OBRA",
      secondImages: "../assets/obras/Vistas_Nudo_Paso-1.jpg",
    },
    {
      id: "acceso-este",
      title: "OBRA",
      subTitle: "ACCESO ESTE",
      imgHeader: "../assets/obras/Portada_condor-1.jpg",
      titleDescription:
        "Este proyecto de repavimentación y ampliación va desde calle Houssay hasta Costanera y  forma parte de la Ruta 7 en el ingreso a la Ciudad de Mendoza.",
      description: [
        {
          text: "El plazo de ejecución fue de  1 año y se realizaron obras complejas como la construcción de un puente, la ampliación de otros dos y el ensanche de la calzada para tener una trocha más de cada lado.",
        },
        {
          text: "En este lugar transitan unos 35 mil vehículos por el Acceso, aproximadamente. Es un acceso central para el corredor bioceánico. Por tal motivo el trabajo realizado fue de vital importancia para la provincia de Mendoza.",
        },
        {
          text: "Con esta obra se aumentó la capacidad de esa ruta y la seguridad de la misma. Se construyó  una trocha más de cada lado y banquinas nuevas (interiores y exteriores).",
        },
        {
          text: "También se remodeló las ramas de ingreso y egreso desde las calles laterales. Uno de los trabajos más importantes fue la construcción del puente  en las calles Sarmiento – Estrada. Y se remodelaron dos puentes  más (en calles Cañadita Alegre – H. Cuadros y Alberdi – Dorrego). El tramo intervenido fue de aproximadamente 3,1 kilómetros.",
        },
      ],
      imgDescription: "../assets/obras/Acceso_Este-1.jpg",
      secondTitle: "VISTAS DE OBRA",
      secondImages: "../assets/obras/Vistas_acceso_este-1.jpg",
    },
    {
      id: "ruta-7-san-luis",
      title: "AUTOPISTA DE LAS",
      subTitle: "SERRANÍAS PUNTANAS",
      imgHeader: "../assets/obras/Portada_Ruta_sanluis-1.jpg",
      titleDescription:
        "Esta obra sobre el corredor biocéancio (Ruta Nacional 7) tiene una extensión de 212 kilómetros de calzada en hormigón, realizada con maquinaria de alta tecnología, única en América del Sur.",
      description: [
        {
          text: "La obra del Corredor Vial Central de la Ciudad de San Luis, se construyó desde un tramo de la Ruta 7 que vincula sectores productivos de la Argentina con Chile y Brasil. Este tramo de la ruta nacional 7 se extiende entre el límite con Córdoba y el río Desaguadero.",
        },
        {
          text: "Esta obra en la provincia de San Luis sobre la Ruta Nacional 7 tiene una extensión de 212 kilómetros de calzada en hormigón, realizada con maquinaria de alta tecnología, única en América del Sur.",
        },
        {
          text: "Uno de los propósitos de esta obra fue mejorar la capacidad de la ruta, incrementando proporcionalmente las condiciones de seguridad. Para ello se proyectó la construcción una calzada paralela a la Ruta Nacional N° 7 existente convirtiéndola en  una autovía con dos calzadas separadas por un cantero central.",
        },
        {
          text: "Este tramo de la ruta nacional 7 se extiende entre el límite con la provincia de Córdoba y el río Desaguadero, límite natural con la provincia de Mendoza, y lo componen dos partes principales: la antigua ruta nacional 7 (de un carril con dos vías, de 3,65 m cada una), a la que se le construyó otro carril de similares características al lado, y otro tramo totalmente nuevo de 14 km de largo, conformada por dos calzadas nuevas que circunvalan la ciudad de San Luis.",
        },
        {
          text: "En ambos casos, los caminos quedarán separados por un cantero central de dimensiones variables, según la disponibilidad de ancho de zona de camino , y tendrán banquina, iluminación y barandas metálicas en los tramos, en curva y cuando el terraplén que eleva el camino (y que evita que se inunde con las lluvias) supere los 2,00 mts de altura sobre el préstamo.",
        },
        {
          text: "Cabe aclarar que el Proyecto Ejecutivo fue desarrollado por la Empresa, íntegramente, durante la construcción de la obra.",
        },
      ],
      imgDescription: "../assets/obras/Imagen_ruta_web-1.jpg",
      secondTitle: "PROCESO DE OBRA",
      secondImages: "../assets/obras/Proceso_ruta7-1.jpg",
    },
    {
      id: "nudo-costanera",
      title: "NUDO",
      subTitle: "COSTANERA",
      imgHeader: "../assets/obras/Cabecera-final-1.jpg",
      titleDescription:
        "Para mejorar el tránsito en la entrada a la ciudad de Mendoza, se desarrolla un viaducto elevado de doble tablero independiente, materializado sobre el canal Cacique Guaymallén.",
      description: [
        {
          text: "Recopilación de antecedentes, estudios de tránsito para la elaboración del Proyecto Ejecutivo y posterior ejecución de la obra que consta de un viaducto elevado de cuatro carriles, dos por dirección de marcha.",
        },
        {
          text: "El viaducto es una estructura de pórticos y superestructura de vigas y losas pretensadas con sus correspondientes rampas de ascenso y descenso. A esto de agrega cuatro puentes de 15 metros de luz sobre el canal Cacique Guaymallén en la prolongación de las calles Rondeau – 25 de Mayo y Barraquero – Pedro Vargas y ampliación Puente José Vicente Zapata.",
        },
        {
          text: "Esto se complementa con un sistema de iluminación superior e inferior, sistema semafórico y un proyecto de parquización.",
        },
      ],
      imgDescription: "../assets/obras/Imagen_Nudo_Content-1.jpg",
      secondTitle: "PROCESO DE OBRA",
      secondImages: "../assets/obras/Progreso_obra-1.jpg",
    },
    {
      id: "viaducto-san-martin",
      title: "VIADUCTO",
      subTitle: "SAN MARTÍN",
      imgHeader: "../assets/obras/vsm1b.jpg",
      titleDescription: "",
      description: [
        {
          text: "La obra del Viaducto San Martín forma parte del Plan Integral de RER (Red de Expresos Regionales) cuyos principales objetivos son:",
        },
        {
          text: "• Mejorar la calidad de vida de toda la población.",
        },
        {
          text: "• Recuperar el nivel de excelencia que históricamente tuvo el ferrocarril en el área Metropolitana.",
        },
        {
          text: "• Generar un cambio en los patrones de movilidad de la población en general, mejorando la calidad del servicio de transporte público.",
        },
        {
          text: "• Mejorar la conectividad en toda la Región Metropolitana (viajes hacia la Ciudad, dentro de la Ciudad y entre distintos municipios del conurbano bonaerense).",
        },
        {
          text: "• Activar la red de 100 km de trenes que tiene la Ciudad de Buenos Aires, mejorando su conectividad y duplicando su frecuencia.",
        },
        {
          text: "• Mejorar la conectividad del ferrocarril con los demás modos de transporte, potenciando la red de Subtes y de Metrobus.",
        },
        {
          text: "Con un trayecto de 5 km que se extiende desde Palermo hasta Paternal, se trata de un viaducto ferroviario elevado sobre las vías existentes del FFCC San Martín. Se eliminarán 11 pasos a nivel, por donde el tren pasará en altura (cruces de las vías con las avenidas Corrientes, Newbery, Trelles/Warnes y las calles Honduras, Gorriti, Cabrera, Niceto Vega, Loyola, Ramírez de Velasco y Girardot) y se abrirán otros 10 pasos en los cruces con Castillo, Aguirre, Vera, Villaroel, Iturri, Leiva, Caldas, Concepción Arenal, Montenegro y Santos Dumont. También se elevarán nuevas estaciones en La Paternal y Chacarita, lo que a su vez mejorará la conexión de esta última con la línea “B” de subte.",
        },
      ],
      imgDescription: "",
      secondTitle: "PROCESO DE OBRA",
      secondImages: "",
    },
    {
      id: "canal-intervalles",
      title: "CANAL",
      subTitle: "INTERVALLES",
      imgHeader: "../assets/obras/Canal-cabecera-1.jpg",
      titleDescription:
        "Es una de las obras más importantes realizadas en la Provincia de Jujuy en la última década en lo que hace al sistema de riego del agro.",
      description: [
        {
          text: "El Canal Intervalles es un proyecto que se compone por un canal de hormigón armado, de 11 kilómetros de longitud y una línea de defensas también igual material de 5 kilómetros 500 metros; a partir de ella otra línea de defensas de muro de gaviones de 1 kilómetro 100 metros de longitud.",
        },
        {
          text: "La función fundamental de la obra es colectar el agua que desagua el canal de restitución y llevarla aguas abajo hacia la zona de desembocadura del canal y una segunda función, que potencia la importancia de la obra, que es proteger los barrios construidos en proximidades de la margen derecha del río ciudad con las defensas cuya función primordial es proteger el propio canal.",
        },
        {
          text: "Es una obra trascendente para la producción, importante por el caudal que lleva y la cantidad de hectáreas que se riegan, pero además importante para los pobladores porque protegerá a la ciudad con las defensas que, si bien son para preservar el canal propiamente dicho, también protege del río a gran parte de la zona Sur de la ciudad. La obra se ejecutó en un plazo de 14 meses aproximadamente.",
        },
      ],
      imgDescription: "../assets/obras/Canal-intervalles-1.jpg",
      secondTitle: "VISTAS DE OBRA",
      secondImages: "../assets/obras/Vistas-canal-1.jpg",
    },
    {
      id: "acueducto",
      title: "ACUEDUCTO",
      subTitle: "NOGOLÍ",
      imgHeader: "../assets/obras/Top_acueducto-1.jpg",
      titleDescription:
        "Es una de las obras hídricas más importantes del país. Se trata de un acueducto realizado en San Luis, que por medio de sus ramales conecta y recorre gran parte del noroeste puntano y abastece del líquido vital a unas 25 pequeñas localidad del secano.",
      description: [
        {
          text: "Desde el dique Nogolí parte la estructura que recorre más de 600 kilómetros en total.",
        },
        {
          text: "Es una de las obras hídricas más importantes que se han realizado en esa provincia. Se trata del acueducto  que con sus ramales recorren gran parte del noroeste puntano y abastece del líquido vital a unas 25 pequeñas localidades del secano.",
        },
        {
          text: "El acueducto está compuesto por tres recorridos troncales que se esparcen por 660 kilómetros y en toda la región noroeste de San Luís, llegando incluso a los límites con las provincias de Mendoza y San Juan. Permite además que los canales transporten agua cruda filtrada y mejore el desarrollo agrícola en esta zona semiárida, además de proyectar futuras redes de agua potable.",
        },
        {
          text: "La red de cañerías del acueducto suministra agua de calidad, ya sea para su posterior potabilización para consumo humano o bien para abastecer al ganado y crear nuevas zonas agrícolas en un área de influencia de aproximadamente 770 mil hectáreas.",
        },
      ],
      imgDescription: "../assets/obras/Acueducto-img-1.jpg",
      secondTitle: "PROCESO DE OBRA",
      secondImages: "../assets/obras/Proceso-acueducto-1.jpg",
    },
    {
      id: "dique-las-palmeras",
      title: "DIQUE",
      subTitle: "LAS PALMERAS",
      imgHeader: "../assets/obras/Portada_dique-1.jpg",
      titleDescription:
        "Este dique contiene un espejo de agua de 186 hectáreas y embalsa un volumen de 24 hm3, por lo que es uno de los más grandes de la provincia de San Luis.",
      description: [
        {
          text: "Este dique contiene un espejo de agua de 186 hectáreas y embalsará un volumen de 24 hm3, por lo que es uno de los más grandes de la provincia de San Luis.",
        },
        {
          text: "Permite expandir el área de riego y posibilita la construcción de acueductos para la provisión de agua para uso humano a las localidades de San Francisco, Leandro N. Alem, Luján y poblados como Pozo de los Rayos, Las Tosquitas, Balde de Puerta, entre otros. Con la obra se busca además potenciar el turismo de la región.",
        },
        {
          text: "El cierre principal es una presa de escollera con pantalla de hormigón aguas arriba, con una altura máxima de 45 m, una longitud de 440 m y un ancho de coronamiento de 10 m.",
        },
        {
          text: "El cierre lateral norte es una presa de hormigón convencional de 6 m de altura máxima, 140 m de longitud y un ancho de coronamiento de 8 m.",
        },
        {
          text: "La Torre de Toma es una estructura de hormigón armado de 45 m de altura. El acceso a la Torre de Toma será a través de un puente de 70 m de longitud y 4 m de ancho.",
        },
      ],
      imgDescription: "../assets/obras/Dique_web-1.jpg",
      secondTitle: "VISTAS DE OBRA",
      secondImages: "../assets/obras/Vistas_obra_web-1.jpg",
    },
    {
      id: "barrio-papa-francisco",
      title: "BARRIO",
      subTitle: "PAPA FRANCISCO",
      imgHeader: "../assets/obras/papafrancisco1b.jpg",
      titleDescription: "",
      description: [
        {
          text: "La obra es de Tipología PB + 3 pisos con cubierta metálica parabólica.",
        },
        {
          text: "Se trata de un desarrollo urbanístico de carácter de vivienda social, distribuido de la siguiente manera:",
        },
        {
          text: "• Sector 3: El total de Unidades es de 184 unidades, de 2 y 3 dormitorios, 8 locales comerciales y obras exteriores. Totalizan una cantidad de 14.000m2 en esquemas de edificios de PB y 3 pisos.",
        },
        {
          text: "• Sector 7 y 10: El total de Unidades es de 146 unidades, de 2 y 3 dormitorios, 8 locales comerciales y obras exteriores. El sector 10 corresponde a una plaza, que será cedida al gobierno de la ciudad. Totalizan una cantidad de 14.000m2 en esquemas de edificios de PB y 4 pisos",
        },
        {
          text: "• Sector 8: El total de Unidades es de 148unidades, de 2 y 3 dormitorios, 6 locales comerciales y obras exteriores. Totalizan una cantidad de 14.000m2 en esquemas de edificios de PB y 4 pisos.",
        },
      ],
      imgDescription: "../assets/obras/papafrancisco1a.png",
      secondTitle: "VISTAS DE OBRA",
      secondImages: "../assets/obras/Vistas-papafran-1.jpg",
    },
    {
      id: "procrear-estacion-buenos-aires",
      title: "PROCREAR ESTACIÓN",
      subTitle: "BUENOS AIRES",
      imgHeader: "../assets/obras/procrear01b.jpg",
      titleDescription:
        "Se trata de un desarrollo urbanístico de alta densidad, compuesto por 15 edificios de 7 y 10 pisos distribuidos en 4 sectores, alcanzando una superficie total a construir de 99.133 m2.",
      description: [
        {
          text: "Se trata de un desarrollo urbanístico de alta densidad, compuesto por 15 edificios de 7 y 10 pisos distribuidos en 4 sectores, alcanzando una superficie total a construir de 99.133 m2.",
        },
        {
          text: "El total de Unidades es de 900 – de 1, 2 y 3 dormitorios – y contando también con 306 cocheras subterráneas y 34 locales comerciales en PB. Se proyectaron 12.000 m2 de plazas parquizadas, que se cederán al Gobierno de la Ciudad.",
        },
        {
          text: "Cuenta con el desarrollo de terrazas verdes ajardinadas.",
        },
      ],
      imgDescription: "",
      secondTitle: "VISTAS DE OBRA",
      secondImages: "../assets/obras/Vistas-procrear-1.jpg",
    },
    {
      id: "torres-parque-avellaneda",
      title: "TORRES PARQUE",
      subTitle: "AVELLANEDA",
      imgHeader: "../assets/obras/Cabecera-torres-1.jpg",
      titleDescription:
        "Este espacio cuenta con cinco nuevas torres de vivienda dentro del complejo habitacional Parque Avellaneda, ubicado en  Buenos Aires. La superficie construida es de 35691.79 m2.",
      description: [
        {
          text: "El Parque Presidente Nicolás Avellaneda es un tradicional espacio verde ubicado en el barrio de Avellaneda en la ciudad de Buenos Aires, Argentina.",
        },
        {
          text: "Se encuentra ubicado en Av. Directorio y Lacarra, en el centro del barrio que lleva su mismo nombre, y en sus casi 30 hectáreas alberga un patrimonio natural, histórico y cultural que lo constituye por su extensión en el tercer parque de la Ciudad.",
        },
        {
          text: "En este espacio se inauguraron 5 nuevas torres de vivienda dentro del complejo habitacional Parque Avellaneda, ubicado en el barrio de Villa Soldati.",
        },
        {
          text: "Con este tipo de viviendas se busca también el desarrollo de complejos en el sur de la ciudad; con el fin de incorporar consorcios sustentables.",
        },
        {
          text: "Las viviendas suman un total de 488 unidades funcionales y han sido otorgadas a través de créditos hipotecarios.",
        },
      ],
      imgDescription: "../assets/obras/Torres-Parque-Avellaneda-1.jpg",
      secondTitle: "VISTAS DE OBRA",
      secondImages: "../assets/obras/Vistas-torres-1.jpg",
    },
    {
      id: "viviendas-procrear",
      title: "BARRIO",
      subTitle: "ATSA",
      imgHeader: "../assets/obras/Portada_Procrear-1.jpg",
      titleDescription:
        "Este emprendimiento contó con el financiamiento del Programa de Reconversión Federal Plurianual de Construcción y viviendas. Se realizaron más de 300 casas.",
      description: [
        {
          text: "Las viviendas cuentan con dos dormitorios, cocina comedor, lavandería y baño. De las 319 viviendas, siete están adaptadas para personas con discapacidades diferentes según lo establecido por pliego.",
        },
        {
          text: "Las obras de urbanización e infraestructura de la obra fueron enripiado de calles, cunetas, cordones, banquinas, puentes peatonales, rampas en las esquinas y forestación. También se realizaron obras de red cloacal, agua, gas y eléctrica con luminarias para alumbrado público.",
        },
        {
          text: "La cocina, baño y lavandería, de cada vivienda cuentan con revestimiento cerámico en paredes y cuentan con mesada de granito. Cada vivienda está adaptada con cableado, llaves, tomas y tablero eléctrico completos.",
        },
        {
          text: "La carpintería es de aluminio con marcos de chapa y hojas de chapa inyectada con poliuretano en ventanas y puertas exteriores, las puertas interiores son de madera. Las viviendas se encuentran pintadas de manera completa.",
        },
      ],
      imgDescription: "../assets/obras/Procrear-1.jpg",
      secondTitle: "VISTAS DE OBRA",
      secondImages: "../assets/obras/Progreso_procrear-1.jpg",
    },
    {
      id: "hotel-vista-suites",
      title: "HOTEL",
      subTitle: "VISTA SUITES",
      imgHeader: "../assets/obras/Portada_vista-1.jpg",
      titleDescription:
        "Situado sobre la principal avenida de la ciudad de San Luis; posee 124 suites acondicionadas bajo el concepto superior de hotelería.",
      description: [
        {
          text: "Este moderno edificio de ocho pisos está ubicado en Av. Illia y Falucho a pocas cuadras de la plaza principal de San Luis. En su interior se encuentran habitaciones Junior, Ejecutivas y Superior con excelentes vistas a las Sierras. Además esta obra de arquitectura cuenta con un moderno Restaurant y un Salón de Convenciones. Para los visitantes HOTEL VISTA posee también piscina, Spa con Jacuzzi y Sauna, con dos salas de masajes y sanitarios con vestidores.",
        },
        {
          text: "Este elegante Hotel 4 estrellas superior tiene alrededor de 3500m2 cubiertos. Es una obra simple, moderna, sobria y con ambientes preparados para recibir a visitantes de todo el mundo.",
        },
        {
          text: "Situado sobre la principal avenida de la ciudad de San Luis; posee 124 suites acondicionadas bajo el concepto superior de hotelería, con diferentes categorías, que han sido acondicionadas con altos estándares de calidad y confort, pensando en turistas de excelencia para brindar una estadía placentera e inolvidable.",
        },
      ],
      imgDescription: "../assets/obras/Vista_Suites-1.jpg",
      secondTitle: "VISTAS DE OBRA",
      secondImages: "../assets/obras/Progreso_vista-1.jpg",
    },
    {
      id: "terminal-san-luis",
      title: "TERMINAL",
      subTitle: "SAN LUIS",
      imgHeader: "../assets/obras/Portada_terminal-1.jpg",
      titleDescription:
        "El moderno espacio, contempla un techo inspirado en las líneas ondulantes de las sierras de San Luis, de manera que se integra visualmente al paisaje circundante.",
      description: [
        {
          text: "Se utilizaron gran parte de las instalaciones del predio, demoliendo solo la nave principal y recuperando su estructura de techo.",
        },
        {
          text: "Además se desarrolló un planteo urbanístico que logró la conexión de la Terminal con las distintas vías de circulación.(Ruta Nº 147, Av. Santos Ortiz, Autopista de las Serranías Puntanas, Autopista de Los Puquios, Ruta Nº 20, conexión con vías de ingreso a Terrazas del Portezuelo).",
        },
        {
          text: "El proyecto cuenta con tres niveles que integran el edificio existente con el nuevo, a través de un techo integrador y con un gran hall central de distribución.",
        },
        {
          text: "El techo está inspirado en las líneas ondulantes de las sierras de San Luis, de manera que se integra visualmente al paisaje circundante.",
        },
        {
          text: "En la planta baja se ubica el hall de acceso circulaciones, locales comerciales, patio de comidas, espera para colectivos y dársenas.",
        },
      ],
      imgDescription: "../assets/obras/Terminal_img_web-1.jpg",
      secondTitle: "PROCESO DE OBRA",
      secondImages: "../assets/obras/Proceso_obra_terminal-1.jpg",
    },
  ];

  const watcherId = () => {
    SubObras.forEach((element) => {
      if (element.id === id) {
        setOneObra(element);
      }
    });
  };

  if (!!id && oneObra === null) {
    return <h1>cargando...</h1>;
  }

  return (
    <>
      {!id && (
        <>
          <Header>
            <img src="../assets/obras-back-home.png" />
          </Header>
          <Wrapper className="container">
            {obrasArray.map((obras, index) => (
              <>
                <WrapperTitle key={index}>
                  <h2>{obras.title}</h2>
                  <p>– Seleccione una obra del listado para ampliar –</p>
                </WrapperTitle>
                <WrapperSlider>
                  <ObrasSliderBalls array={obras.component} />
                </WrapperSlider>
              </>
            ))}
          </Wrapper>
        </>
      )}
      {!!id && oneObra && <ParticularObra component={oneObra} />}
      <Footer />
    </>
  );
};

export default Obras;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  width: 100%;
`;

const Header = styled.header`
  width: 100%;
  overflow: hidden;
  img {
    width: 100%;
    overflow: hidden;
  }
`;

const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  color: #8c8c8c;

  h2 {
    font-size: 3.2rem;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
  }
  p {
    font-family: "Open Sans", sans-serif;

    font-size: 1.5rem;
  }

  @media (max-width: 900px) {
    text-align: center;
    h2 {
      font-size: 2.6rem;
    }
    p {
      font-size: 1rem;
    }
  }
`;

const WrapperSlider = styled.div`
  /* width: 100%; */
  width: 60%;
  margin: 2rem auto;
`;
