import React from "react";
import styled from "styled-components";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import AboutMain from "../components/main/AboutMain";
import Services from "../components/main/Services";

const Main = () => {
  return (
    <Wrapper>
      <Header />
      <AboutMain />
      <Services />
      <Footer />
    </Wrapper>
  );
};

export default Main;

const Wrapper = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
