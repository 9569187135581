import styled from "styled-components";

export const ButtonCm = ({ text, size = "2rem" }) => (
  <Button size={size}>{text}</Button>
);

const Button = styled.button`
  padding: 0.5rem 0.7rem;
  width: fit-content;
  height: fit-content;
  font-family: "Josefin Sans", sans-serif;
  font-size: ${(p) => p.size};
  color: #fff;
  font-weight: 300;
  cursor: pointer;
  border: none;
  background-color: #12a451;
  box-shadow: none;
`;
