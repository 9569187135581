import React from "react";
import styled from "styled-components";

const ParticularObra = ({ component }) => {
  return (
    // <div>
    //   <h1>{component.id}</h1>
    //   <h1>{component.title}</h1>
    //   <h1>{component.imgHeader}</h1>
    //   <h1>{component.titleDescription}</h1>
    //   <h1>{component.description}</h1>
    //   <h1>{component.imgDescription}</h1>
    // </div>

    <Wrapper>
      <Header colorH2="#fff" colorH3="#DDD485" img={`${component.imgHeader}`}>
        <h2>{component.title}</h2>
        <h3>{component.subTitle}</h3>
        <p>{component.titleDescription}</p>
      </Header>
      <BodyDescription className="container">
        <Left>
          <h3>Descripción</h3>
          {component.description.map((texts) => (
            <p>{texts.text}</p>
          ))}
        </Left>
        <Right>
          <img src={`${component.imgDescription}`} />
        </Right>
      </BodyDescription>
      {!!component.secondImages && (
        <ImagesWrapper className="container">
          <h3>{component.secondTitle}</h3>
          <img src={`${component.secondImages}`} />
        </ImagesWrapper>
      )}
    </Wrapper>
  );
};

export default ParticularObra;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
`;

const Header = styled.div`
  background-image: url(${(p) => p.img});
  width: calc(100% - 40%);
  height: calc(450px - 4rem);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  padding: 2rem 20%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: flex-start;

  h2 {
    font-size: 4rem;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    color: ${(p) => p.colorH2};
  }
  h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 4rem;
    color: ${(p) => p.colorH3};
  }
  p {
    color: #fff;
    font-size: 1.3rem;
    font-family: "Open Sans", sans-serif;
  }

  @media (max-width: 1300px) {
    h2 {
      font-size: 3rem;
    }
    h3 {
      font-size: 3rem;
    }
    p {
      color: #fff;
      font-size: 1rem;
    }
  }

  @media (max-width: 900px) {
    text-align: center;
    align-items: center;

    h2 {
      font-size: 2.8rem;
      text-align: center;
    }
    h3 {
      font-size: 2.8rem;
      text-align: center;
    }
    p {
      color: #fff;
      font-size: 0.9rem;
    }
  }
`;

const BodyDescription = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  flex-basis: 65%;
  h3 {
    color: #12a351;
    text-transform: uppercase;
    font-size: 2.5rem;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
  }
  p {
    font-family: "Open Sans", sans-serif;
    color: #696969;
    font-size: 1.2rem;
  }
  @media (max-width: 1300px) {
    flex-basis: 50%;

    h3 {
      font-size: 2.2rem;
    }
    p {
      font-size: 1.1rem;
    }
  }

  @media (max-width: 900px) {
    h3 {
      font-size: 2rem;
    }
    p {
      font-size: 0.9rem;
    }
  }
`;

const Right = styled.div`
  width: 100%;
  flex-basis: 35%;
  img {
    width: 100%;
  }

  @media (max-width: 1300px) {
    flex-basis: 50%;
  }
`;

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;

  h3 {
    color: #436b72;
    text-transform: uppercase;
    font-size: 2.5rem;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
  }

  img {
    /* width: 100%; */
    max-height: 251px;
    object-fit: contain;
  }

  @media (max-width: 1300px) {
    h3 {
      font-size: 2.2rem;
    }

    img {
      /* max-height: 251px; */
      object-fit: cover;
      width: 100%;
    }
  }
`;
