import React from "react";
import styled from "styled-components";

const BodyChannel = () => {
    return (
        <Wrapper className="container">
            <h2>CANAL TRANSPARENTE Y CÓDIGO DE CONDUCTA</h2>
            <Texts>
                <p>
                    El Programa de Integridad de Green S.A. comprende una serie
                    de documentos y directivas y busca minimizar riesgos y
                    promover una cultura de conducta ética y transparente.
                </p>
                <p>
                    Nuestro Código de Conducta presenta principios y
                    procedimientos éticos que rigen las relaciones entre Green
                    S.A, sus empleados y terceros.
                </p>
                <p>
                    Implementamos un marco normativo que contempla la adopción
                    de medidas preventivas para desterrar la corrupción y el
                    soborno y refleja los requerimientos de las leyes y
                    regulaciones que aplican.
                </p>
                <p>
                    Todos los empleados de Green S.A. deben conocer y aplicar
                    las disposiciones del presente Código en el ejercicio de sus
                    funciones y en el relacionamiento con terceros. Este
                    conocimiento y acción concreta son requisitos básicos de
                    empleo en la Empresa.
                </p>
                <p>
                    Ante consultas, conocimiento de alguna infracción o no
                    cumplimiento de disposiciones del presente Código, los
                    empleados, proveedores y terceros tienen acceso a nuestro
                    “Canal Transparente” dispuesto por Green S.A. para realizar
                    la respectiva denuncia, para lo cual se han tomado medidas
                    para mantener la confidencialidad de las mismas.
                </p>
            </Texts>
            <div className="email">
                <p>
                    Correo: <span>canaltransparente@greensa.com.ar</span>
                </p>
            </div>
            <Download>
                <a href="./assets/DIR-GU-02-Rev01-Código-de-conducta.pdf" target="_blank">
                    Descargue aquí el código de conducta
                </a>
            </Download>
        </Wrapper>
    );
};

export default BodyChannel;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 4rem;

    h2 {
        font-size: 4rem;
        font-family: "Open Sans", sans-serif;
        font-weight: 300;
        text-align: center;
        color: #12a451;
    }

    .email {
        p {
            color: #696969;
            font-size: 1.2rem;
            line-height: 150%;

            span {
                color: #12a451;
            }
        }
    }

    @media (max-width: 900px) {
        h2 {
            font-size: 2.5rem;
        }

        .email {
            p {
                font-size: 1rem;
                line-height: 140%;
            }
        }
    }
`;

const Texts = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    p {
        color: #696969;
        text-align: justify;
        font-size: 1.2rem;
        line-height: 150%;
        font-family: "Open Sans", sans-serif;
    }

    @media (max-width: 900px) {
        p {
            font-size: 1rem;
            line-height: 140%;
        }
    }
`;

const Download = styled.div`
    a {
        color: #222;
        font-size: 1.2rem;
        text-decoration: none;
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
    }
`;
