import React from "react";
import styled from "styled-components";

const BodyEmpresa = () => {
  const sections = [
    {
      title: "VISIÓN",
      direction: "right",
      color: "#bc6948",
      letter: "V",
      description: `La Visión de GREEN es proyectar y construir obras, afianzando el crecimiento responsable de la sociedad, de nuestra organización y de las personas que la integran.`,
    },
    {
      title: "MISIÓN",
      direction: "left",
      color: "#4994ba",
      letter: "M",
      description: `Continuar siendo una de las empresas líderes de la región, gozando de alto prestigio de nuestros clientes, la sociedad y los competidores. Trabajar para que nuestra gente perciba crecimiento en su bienestar, tanto en lo individual como en lo colectivo.`,
    },
  ];

  return (
    <Wrapper className="container">
      <FirstSection>
        <FirstDiv>
          <Header color="#12a451">
            <Letter color="#12a451">H</Letter>
            <h2>HISTORIA</h2>
          </Header>
          <FirstDescription>
            <p>
              En 1991, Carlos Arroyo fundó GREEN S.A. con la visión de crear
              una empresa constructora que no solo creciera de manera constante
              sino que también realizara las obras más importantes del pais.
              Desde sus inicios, GREEN S.A. se ha dedicado al desarrollo integral
              de proyectos de edificación y obras viales que ahora llevan su sello
              de calidad por todo el país.
              
              {/* GREEN S.A. nació en el año 1991, iniciando su actividad como
              Empresa Constructora, y dirigiendo su accionar al desarrollo
              integral de proyectos de edificación y de obras viales que ahora
              llevan su sello de calidad en Argentina y América. Desde entonces,
              continúa la evolución de sus capacidades, lo que permite sostener
              el crecimiento empresario necesario para participar en proyectos
              de exigente complejidad técnica: obras hidráulicas,
              arquitectónicas, de saneamiento y ductos. */}
            </p>
          </FirstDescription>
        </FirstDiv>
        <SecondDiv>
          <p>
            A lo largo de los años GREEN S.A. ha crecido y evolucionado de forma
            constante, lo que permitió participar en una amplia variedad de obras
            de infraestructura, incluyendo proyectos viales, hidráulicos y arquitectónicos.
            Además de ejecutar y licitar grandes obras en Argentina manteniendo el compromiso
            con la calidad y la excelencia.
{/* 
            La construcción es una industria dinámica. GREEN S.A. se adapta a
            los cambios y a las circunstancias del mercado trabajando en obra
            pública y moldeando sus capacidades a las ofertas de los distintos
            oferentes comitentes, gobierno nacional o de las provincias. */}
          </p>
          <p>
            La construcción en nuestro país es una industria dinámica y GREEN S.A.
            acompaña ese dinamismo adaptándose a los cambios y circunstancias del 
            mercado, ajustando las capacidades según necesidades de cada oferente.
            {/* GREEN S.A. se especializa en todo tipo de obras de infraestructura;
            viales o hidráulicas; también de arquitectura. Se construyen y
            licitan importantes obras en Argentina y el exterior. */}
            <br />
            <br />
            Para GREEN S.A., la construcción no solo es la madre de industrias, sino también
            una generadora vital de empleo e insumos.
{/*      
            Para GREEN S.A., la construcción es madre de industrias y generadora
            de fuente de trabajo e insumos. */}
          </p>
        </SecondDiv>
      </FirstSection>
      <SecondSection>
        {sections.map((section, index) => (
          <Section color={section.color} key={index}>
            <Header color={section.color}>
              <Letter color={section.color}>{section.letter}</Letter>
              <h2>{section.title}</h2>
            </Header>
            <Description color={section.color}>
              <p>{section.description}</p>
            </Description>
          </Section>
        ))}
      </SecondSection>
    </Wrapper>
  );
};

export default BodyEmpresa;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 2rem;
  align-items: center;
`;

const FirstSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`;

const FirstDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @media (max-width: 900px) {
    align-items: center;
  }
`;

const FirstDescription = styled.div`
  width: 100%;

  p {
    color: #696969;
    font-size: 1rem;
    font-family: "Open Sans", sans-serif;
    text-align: justify;
  }
`;

const SecondDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  p {
    color: #696969;
    font-size: 1rem;
    font-family: "Open Sans", sans-serif;
    text-align: justify;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const SecondSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 2rem;
  gap: 2rem;
  width: 100%;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 900px) {
    align-items: center;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  h2 {
    color: ${(p) => p.color};
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 3rem;
  }

  @media (max-width: 900px) {
    h2 {
      text-align: center;
    }
  }
`;

const Letter = styled.div`
  height: 60px;
  width: 60px;
  padding: 0.3rem;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: ${(p) => p.color};

  @media (max-width: 900px) {
    display: none;
  }
`;

const Description = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  p {
    color: #696969;
    font-size: 1rem;
    font-family: "Open Sans", sans-serif;
    text-align: justify;
  }
`;
