import React from "react";
import styled from "styled-components";

const AboutMain = () => {
  return (
    <Wrapper>
      <Card>
        <Letter color="#12a451">T</Letter>
        <Info color="#12a451">
          <h2>TRAYECTORIA</h2>
          <p>
            La Sociedad Anónima GREEN S.A. fue fundada en el año 1991, iniciando
            su actividad como Empresa Constructora, y dirigiendo su accionar al
            desarrollo integral de proyectos de edificación y de obras viales.
            Desde entonces, la continua evolución de sus capacidades le permitió
            sostener el crecimiento empresario necesario para participar en
            proyectos de exigente complejidad técnica: obras hidráulicas,
            arquitectónicas, de saneamiento, y ductos.
          </p>
        </Info>
      </Card>
      <Card>
        <Letter color="#bc6948">C</Letter>
        <Info color="#bc6948">
          <h2>CONSTRUIMOS</h2>
          <p>
            Rutas, barrios, autopistas, gasoductos, edificios en torre, presas,
            hoteles, viaductos, escuelas, acueductos, hospitales, puentes y
            obras de saneamiento y de urbanización llevan su sello de calidad en
            Argentina y América del Sur. Green S.A. posee actualmente en la
            Argentina, tres sedes en Mendoza, San Luis y Buenos Aires. También
            importantes obras con personal propio y administración en Colombia y
            Paraguay.
          </p>
        </Info>
      </Card>
    </Wrapper>
  );
};

export default AboutMain;

const Wrapper = styled.div`
  margin: 2rem auto;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const Card = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: flex-start;
`;

const Letter = styled.div`
  color: #fff;
  background-color: ${(p) => p.color};
  padding: 2rem;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 300;

  @media (max-width: 900px) {
    display: none;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  h2 {
    color: ${(p) => p.color};
    font-family: "Open Sans", sans-serif;
    font-size: 40px;
    font-weight: 300;
  }

  p {
    font-family: "Open Sans", sans-serif;
    color: #696969;
    line-height: 150%;
    font-size: 1.2rem;
  }

  @media (max-width: 900px) {
    align-items: center;

    h2 {
      font-size: 38px;
      text-align: center;
    }

    p {
      line-height: 130%;
      font-size: 1rem;
      text-align: center;
    }
  }
`;
