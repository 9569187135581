import { Form, Formik } from "formik";
import React, { useState } from "react";
import styled from "styled-components";
import { contactSchema } from "../../schema/contactSchema";
import DefaultField from "../commons/DefaultField";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const initialValues = {
    name: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };

  const [isSend, setIsSend] = useState(false);

  const handleSubmit = () => {
    emailjs
      .sendForm(
        "service_ve8g3ti",
        "template_dtesi6j",
        "#myForm",
        "6gqkXylz33QsGtM6m"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );

    setIsSend(true);
  };

  return (
    <Wrapper className="container">
      <Formik
        initialValues={initialValues}
        // onSubmit={handleSubmit}
        validationSchema={contactSchema}
      >
        {({ values, errors, setFieldValue, handleChange, handleReset }) => (
          <Container id="myForm">
            <Title>Dejanos un mensaje, te responderemos a la brevedad.</Title>
            <WrapperField>
              <WrapperInput>
                <DefaultField
                  setFieldValue={setFieldValue}
                  disabled={isSend ? true : false}
                  handleChange={handleChange}
                  value={values.name}
                  name="name"
                  type="text"
                  label="Nombre"
                  required
                />
                {errors.name && <Error>{errors.name}</Error>}
              </WrapperInput>
              <WrapperInput>
                <DefaultField
                  setFieldValue={setFieldValue}
                  disabled={isSend ? true : false}
                  handleChange={handleChange}
                  value={values.lastName}
                  name="lastName"
                  type="text"
                  label="Apellido"
                  required
                />
                {errors.lastName && <Error>{errors.lastName}</Error>}
              </WrapperInput>
            </WrapperField>
            <WrapperField>
              <WrapperInput>
                <DefaultField
                  setFieldValue={setFieldValue}
                  disabled={isSend ? true : false}
                  handleChange={handleChange}
                  value={values.email}
                  name="email"
                  type="email"
                  label="Correo Electrónico"
                  required
                />
                {errors.email && <Error>{errors.email}</Error>}
              </WrapperInput>
              <WrapperInput>
                <DefaultField
                  setFieldValue={setFieldValue}
                  disabled={isSend ? true : false}
                  handleChange={handleChange}
                  value={values.phone}
                  name="phone"
                  type="number"
                  label="Teléfono o Celular"
                  required
                />
                {errors.phone && <Error>{errors.phone}</Error>}
              </WrapperInput>
            </WrapperField>

            {/** Descripcion */}
            <WrapperInput>
              <DefaultField
                setFieldValue={setFieldValue}
                disabled={isSend ? true : false}
                handleChange={handleChange}
                value={values.message}
                name="message"
                type="text"
                label="Mensaje"
                multiline
                subtype="textarea"
                rows={5}
                required
              />
              {errors.message && <Error>{errors.message}</Error>}
            </WrapperInput>

            {!isSend ? (
              <Button
                onClick={() => {
                  if (
                    values.name &&
                    values.email &&
                    values.phone &&
                    values.lastName &&
                    values.message
                  ) {
                    handleSubmit(values);
                    handleReset();
                  }
                }}
              >
                Enviar
              </Button>
            ) : (
              <SendMessage>Mensaje enviado</SendMessage>
            )}
          </Container>
        )}
      </Formik>
    </Wrapper>
  );
};

export default ContactForm;

const Wrapper = styled.div``;

const Container = styled(Form)`
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  width: calc(100% - 8rem);
  row-gap: 2rem;
  padding: 2rem 4rem;

  background-color: #dce8f1;

  @media (max-width: 768px) {
    padding: 2rem;
  }

  @media (max-width: 425px) {
    padding: 2rem 1rem;
  }
`;

const WrapperField = styled.div`
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  gap: 2rem;
  /* border: solid 1px red; */

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Title = styled.p`
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #445859;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;

  @media(max-width: 900px) {
  font-size: 1.3rem;
    
  }
`;

const Button = styled.button`
  background-color: #445859; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius: 10px;
  cursor: pointer;
  /* width: fit-content; */
  transition: all 0.3s ease;
  :hover {
    background-color: #596b6c;
  }
`;

const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  width: 100%;
`;

const Error = styled.span`
  font-size: 0.75rem;
  font-weight: 700;
  color: #b31d15;
`;

const SendMessage = styled.div`
  width: calc(100% - 2rem);
  background-color: lightgreen;
  padding: 1rem;
  text-align: center;
  font-size: 2rem;
  font-family: "Open Sans", sans-serif;
  color: #222;
  border-radius: 10px;
`;
