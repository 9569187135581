import React from "react";

import { ErrorMessage, Field } from "formik";
import styled from "styled-components";

const DefaultField = (props) => {
  const {
    name,
    type,
    value,
    disabled,
    label,
    handleChange,
    subtype = "input",
  } = props;

  // RETURN
  return (
    // <FieldFake
    //   subtype={subtype}
    //   name={name}
    //   value={value}
    //   onChange={(e, newValue) => {
    //     setFieldValue(name, e.target.value);
    //   }}
    //   as={subtype}
    //   helperText={<ErrorMessage name={name} component={CustomError} />}
    //   type={type}
    //   placeholder={label}
    //   variant="standard"
    //   color="primary"
    //   size="small"
    //   fullWidth
    //   {...props}
    // />
    <Wrapper>
      <Label>{label}</Label>
      <FieldFake
        type={type}
        name={name}
        onChange={handleChange}
        //   onBlur={handleBlur}
        disabled={disabled}
        value={value}
        subtype={subtype}
      />
    </Wrapper>
  );
};

export default DefaultField;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const Label = styled.p`
  color: #445859;
`;

const FieldFake = styled.input`
  height: ${(p) => (p.subtype === "textarea" ? "100px" : "20px")};
  width: 100%;
  max-width: calc(100% - 2rem);
  background-color: #fff;
  border: none;
  padding: 1rem;
  border-radius: 10px;
  font-size: 1.2rem;
  color: #445859;
  outline: none;
  transition: all 0.3s ease;

  :disabled {
    background-color: lightgray;
  }

  ::placeholder {
    color: #445859;
    font-size: 1.2rem;
  }

  :focus {
    border: solid 1px #445859;
  }
`;
