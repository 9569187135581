import React from "react";
import styled from "styled-components";

const HeaderEmpresa = () => {
  return (
    <Wrapper>
      <video src="./assets/Banner-empresa.webm" autoPlay muted loop />
      <Div>
        <h2>"Diseñamos, proyectamos y construimos"</h2>
        <p>
          En beneficio de la sociedad en general y de los integrantes de la
          familia de la empresa en particular, favoreciendo su desarrollo y
          crecimiento personal.
        </p>
      </Div>
    </Wrapper>
  );
};

export default HeaderEmpresa;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 900px) {
    height: 650px;
  }

  @media (max-width: 1150px) {
    height: 450px;
  }
`;

const Div = styled.div`
  position: absolute;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h2 {
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 3rem;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: 900px) {
      font-size: 2.3rem;
    }
  }

  p {
    text-align: center;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;

    @media (max-width: 900px) {
      font-size: 0.85rem;
      line-height: 180%;
    }
  }
`;
