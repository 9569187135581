import React from "react";
import styled from "styled-components";
import ContactForm from "../components/contacto/ContactForm";

const Contacto = () => {
  return (
    <Container>
      <Wrapper>
        <Locations>
          <Location>
            <h3>SAN LUIS</h3>
            <p>Av. Presidente Perón N° 746, C.P. 5700 Teléfono /</p>
            <p>fax: +54 266 4440755 / 4443881 E-mail:</p>
            <p>info@greensa.com.ar</p>
          </Location>
          <Location>
            <h3>MENDOZA</h3>
            <p>Huarpes N° 259, C.P. 5502.</p>
            <p>Teléfono / fax: +54 261 3829756 E-mail:</p>
            <p>info@greensa.com.ar</p>
          </Location>
        </Locations>
      </Wrapper>
      <ContactForm />
    </Container>
  );
};

export default Contacto;

const Container = styled.div``;

const Wrapper = styled.div`
  width: 100%;
  height: 550px;
  background-position: center right;
  background-image: url("../assets/banner-contacto.png");
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @media (max-width: 1400px) {
    background-position: center center;
  }
`;

const Locations = styled.div`
  position: absolute;
  top: 10%;
  right: 20%;
`;

const Location = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-end;

  h3 {
    color: #fff;
    font-size: 2.5rem;
    text-transform: uppercase;
    text-shadow: black 0.1em 0.1em 0.2em;
    font-family: "Open Sans", sans-serif;
  }

  p {
    color: #95dbb2;
    font-size: 1.2rem;
    font-family: "Open Sans", sans-serif;
    text-shadow: black 0.1em 0.1em 0.2em;
  }

  @media (max-width: 1600px) {
    h3 {
      font-size: 2.3rem;
    }
    p {
      font-size: 1.1rem;
    }
  }

  @media (max-width: 1400px) {
    h3 {
      font-size: 2rem;
    }
    p {
      font-size: 0.9rem;
    }
  }
`;
