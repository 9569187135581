import React from "react";
import styled from "styled-components";

const ValuesEmpresa = () => {
    const array = [
        {
            info: [
                {
                    span: "Perseverancia y compromiso:",
                    text: "Ser tenaces en el logro de los objetivos propuestos.",
                },
                {
                    span: "Pasión:",
                    text: "Trabajar con motivación y entusiasmo.",
                },
                {
                    span: "Solidaridad y comunicación:",
                    text: "Hacer el trabajo en equipo. Cooperar entre áreas y sectores.",
                },
                {
                    span: "Iniciativa y creatividad:",
                    text: "Tener la libertad de proponer nuevas ideas, en busca de mejorar procesos, lograr objetivos y alcanzar resultados.",
                },
            ],
        },
        {
            info: [
                {
                    span: "Honestidad y Humildad:",
                    text: "Ser transparente y comunicar transparencia. Tener humildad para reconocer errores.",
                },
                {
                    span: "Responsabilidad:",
                    text: "Tener capacidad para dar respuesta. Cumplir en tiempo y forma nuestros compromisos.",
                },
                {
                    span: "Disciplina:",
                    text: "Cumplir las normas, procedimientos e instrucciones.",
                },
            ],
        },
    ];

    return (
        <Wrapper className="container">
            <FirstWrapper>
                <h3>Valores de la empresa</h3>
                <InfoWrapper>
                    {array.map((info, index) => (
                        <Texts key={index}>
                            {info.info.map((texts, index) => (
                                <TextsSpan key={index}>
                                    <h3>{texts.span}</h3> <p>{texts.text}</p>
                                </TextsSpan>
                            ))}
                        </Texts>
                    ))}
                </InfoWrapper>
            </FirstWrapper>
            <SecondWrapper>
                <DivImage>
                    <img src="./assets/imagen-ninos-fondo.jpg" />
                </DivImage>
                <DivTexts>
                    <h3>RESPONSABILIDAD SOCIAL EMPRESARIA</h3>
                    <p>
                        Green desarrolla proyectos de Responsabilidad Social
                        Empresaria en una comunidad de Mendoza, Argentina. El
                        objetivo es otorgar becas de estudios primarios a niños
                        carenciados. Nuestro personal se ha comprometido con
                        esta acción solidaria y acompaña el proceso educativo de
                        estos niños y sus familias.
                    </p>
                </DivTexts>
            </SecondWrapper>
            <PolicyWrapper>
                <p>Política de gestión</p>
                <Download>
                    <a
                        href="./assets/Política-de-gestión-Rev01.pdf"
                        target="_blank"
                    >
                        Descargar política de gestión
                    </a>
                </Download>
            </PolicyWrapper>
        </Wrapper>
    );
};

export default ValuesEmpresa;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4rem;
`;

const FirstWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    width: 100%;

    h3 {
        color: #acac6a;
        font-size: 3.2rem;
        font-family: "Open Sans", sans-serif;
        font-weight: 300;
        text-transform: uppercase;
    }

    @media (max-width: 900px) {
        h3 {
            font-size: 3rem;
            text-align: center;
        }
    }
`;

const InfoWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
    }
`;

const Texts = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
`;

const TextsSpan = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.1rem;
    flex-wrap: wrap;

    h3 {
        color: #acac6a;
        font-size: 1rem;
        font-weight: 300;
        font-family: "Open Sans", sans-serif;
    }

    p {
        color: #696969;
        font-size: 1rem;
        font-family: "Open Sans", sans-serif;
    }
`;

const SecondWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 2rem;

    @media (max-width: 900px) {
        display: flex;
        flex-direction: column-reverse;
    }
`;

const DivImage = styled.div`
    width: 100%;

    img {
        width: 100%;
        object-fit: cover;
    }
`;

const DivTexts = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    h3 {
        color: #393836;
        font-size: 3rem;
        text-align: start;
        font-family: "Open Sans", sans-serif;
        font-weight: 300;
    }
    p {
        color: #696969;
        font-size: 1rem;
        font-family: "Open Sans", sans-serif;
        line-height: 160%;
    }

    @media (max-width: 900px) {
        h3 {
            text-align: center;
        }
        p {
            text-align: center;
        }
    }
`;

const Download = styled.div`
    text-align: center;
    a {
        color: #222;
        font-size: 1.2rem;
        text-decoration: none;
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
    }
`;

const PolicyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    p {
        color: #acac6a;
        font-size: 2.3rem;
        font-family: "Open Sans", sans-serif;
        font-weight: 300;
        text-transform: uppercase;
    }
`;
