import React, { useState } from "react";
import styled from "styled-components";
import Carousel from "react-elastic-carousel";
import { useNavigate } from "react-router";

const ObrasSliderBalls = ({ array }) => {
  const navigate = useNavigate();

  const [size, setSize] = useState(document.documentElement.scrollWidth);

  const handleWidth = () => {
    setSize(document.documentElement.scrollWidth);
  };

  window.addEventListener("resize", handleWidth);

  let quantity = 5;

  if (array.length > 5) {
    if (size < 768) {
      quantity = 2;
    }
    if (size > 768 && size < 900) {
      quantity = 3;
    }

    if (size > 900 && size < 1200) {
      quantity = 4;
    }

    if (size > 1200) {
      quantity = 5;
    }
  }

  if (array.length === 3) {
    if (size < 768) {
      quantity = 2;
    }
    if (size > 768) {
      quantity = 3;
    }
  }

  if (array.length === 1) {
    quantity = 1;
  }

  return (
    <Wrapper>
      <CarouselFake
        transitionMs={500}
        showArrows={false}
        autoPlaySpeed={2000}
        itemsToShow={quantity}
        enableAutoPlay
      >
        {array.map((obra) => (
          <BallsWrapper
            title={obra.alt}
            onClick={() => {
              navigate(`./${obra.path}`);
            }}
          >
            <img src={obra.img} alt={obra.alt} />
          </BallsWrapper>
        ))}
      </CarouselFake>
    </Wrapper>
  );
};

export default ObrasSliderBalls;

const Wrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  user-select: none;

  :active {
    cursor: pointer;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

const CarouselFake = styled(Carousel)`
  height: fit-content;
  position: relative;
  display: flex;
  gap: 1rem;
  width: 100%;
  padding: 1rem 0;
  .rec-slider-container {
    margin: 0;
  }
  .rec.rec-pagination {
    position: absolute;
    bottom: -10px;
    left: 50%;
    button {
      background: #b5b6b6;
      box-shadow: none;
    }
    .rec-dot_active {
      border: none;
      background-color: #e9e9e9;
    }
  }
`;

const BallsWrapper = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    width: 100%;
    overflow: hidden;
    object-fit: cover;
  }
`;
