import React from "react";
import styled from "styled-components";
import Footer from "../components/layout/Footer";

const Equipamiento = () => {
  return (
    <>
      <Wrapper>
        <img src="./assets/equipos-back.png" />
      </Wrapper>
      <Footer />
    </>
  );
};

export default Equipamiento;

const Wrapper = styled.div`
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: -4rem;
  img {
    width: 100%;
  }

  @media (max-width: 900px) {
    background-image: url("../assets/equipos-back-mobile.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    img {
      display: none;
    }
  }

  @media (max-width: 768px) {
    background-size: contain;
    width: 100%;
    background-position: top left;
  }
`;
