import React from "react";
import styled from "styled-components";
import BodyChannel from "../components/canalTransparente/bodyChannel";
import Footer from "../components/layout/Footer";

const CanalTransparente = () => {
  return (
    <Wrapper>
      <BodyChannel />
      <Footer />
    </Wrapper>
  );
};

export default CanalTransparente;

const Wrapper = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
